var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Top',{attrs:{"title":"修改密码","path":"/my-detail"}}),_c('div',{staticClass:"pt44"},[_c('a-form',{staticClass:"reset-form",attrs:{"form":_vm.form}},[_c('a-form-item',{staticClass:"pt20",attrs:{"label":"旧密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'oldPassword',
            {
              rules: [{ required: true, message: '请输入旧密码' }],
            } ]),expression:"[\n            'oldPassword',\n            {\n              rules: [{ required: true, message: '请输入旧密码' }],\n            },\n          ]"}],attrs:{"allow-clear":"","placeholder":"请输入旧密码"}})],1),_c('a-form-item',{attrs:{"label":"新密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'newPassword',
            {
              rules: [{ required: true, message: '请输入新密码' }],
            } ]),expression:"[\n            'newPassword',\n            {\n              rules: [{ required: true, message: '请输入新密码' }],\n            },\n          ]"}],attrs:{"allow-clear":"","placeholder":"请输入新密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirmPassword',
            {
              rules: [{ required: true, message: '请确认密码' }],
            } ]),expression:"[\n            'confirmPassword',\n            {\n              rules: [{ required: true, message: '请确认密码' }],\n            },\n          ]"}],attrs:{"allow-clear":"","placeholder":"请确认密码"}})],1)],1)],1),_c('div',{staticClass:"reset-btn-area"},[_c('a-button',{staticClass:"login-btn",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 确认 ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }