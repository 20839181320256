<template>
  <div>
    <Top title="修改密码" path="/my-detail" />
    <div class="pt44">
      <a-form :form="form" class="reset-form">
        <a-form-item label="旧密码" class="pt20">
          <a-input-password
            v-decorator="[
              'oldPassword',
              {
                rules: [{ required: true, message: '请输入旧密码' }],
              },
            ]"
            allow-clear
            placeholder="请输入旧密码"
          />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password
            v-decorator="[
              'newPassword',
              {
                rules: [{ required: true, message: '请输入新密码' }],
              },
            ]"
            allow-clear
            placeholder="请输入新密码"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password
            v-decorator="[
              'confirmPassword',
              {
                rules: [{ required: true, message: '请确认密码' }],
              },
            ]"
            allow-clear
            placeholder="请确认密码"
          />
        </a-form-item>
      </a-form>
    </div>
    <div class="reset-btn-area">
      <a-button type="primary" class="login-btn" @click="submit">
        确认
      </a-button>
    </div>
  </div>
</template>
<script>
import { Form, Input, Button } from "ant-design-vue";
import Top from "@/components/top/top";
import { changePassword } from "@/utils/api";
export default {
  components: {
    Top,
    AForm: Form,
    AFormItem: Form.Item,
    AButton: Button,
    AInputPassword: Input.Password,
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: "reset-form" }),
    };
  },
  methods: {
    submit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          };
          this.changePassword(params);
        }
      });
    },
    async changePassword(params) {
      const { resp_msg, resp_code } = await changePassword(params);
      if (resp_code == 0) {
        this.$message.success(resp_msg, 2, () => {
          this.form.resetFields();
          // localStorage.removeItem("loginParams");
          localStorage.clear();
          this.$router.push("/home");
        });
      } else {
        this.$message.error(resp_msg);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.reset-form {
  background: #fff;
  width: 100%;
  margin-top: 10px;
  padding: 0 8.13% 2rem 8.13%;
}
.login-btn {
  width: 100%;
  height: 4.4rem;
  background: linear-gradient(270deg, #f39800 0%, #ffac22 100%);
  border-radius: 4.4rem;
  font-size: 1.7rem;
}
.reset-btn-area {
  padding: 0 8.13%;
  margin-top: 10rem;
}
</style>